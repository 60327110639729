import React from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../routes';

const NotFound: React.FC = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center py-3">
        <div className="col-4 h4 font-italic">
          <hr />
          Greetings traveler, I believe the page you are looking for doesn't exist.
        </div>
      </div>
      <div className="row justify-content-center pb-3">
        <div className="col-4 h5">
          <strong>404 btw.</strong>
          <hr />
        </div>
      </div>
      <div className="row justify-content-center pb-3">
        <div className="col-4">
          Please redirect
          {' '}
          <strong className="h5"><Link to={Paths.Home}>here</Link></strong>
          .
        </div>
      </div>
    </div>
  );
};

export default NotFound;
