import React, { Fragment, useContext } from 'react';
import styles from './trending-production-card.module.css';
import DarkButton from '../dark-button';
import DragZone from './drag-zone';
import DropZone from './drop-zone';
import DraggablePoster from './draggable-poster';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';
import useVodProviderPopup from '../../hooks/use-vod-provider-popup';
import PurpleButton from '../purple-button';

const TrendingProductionCard: React.FC<{
  id: number;
  poster: string;
  type: string;
  genres: Array<string>;
  title: string;
  position: number;
}> = ({
  id,
  poster,
  type,
  genres,
  title,
  position,
}) => {
  const { dispatch } = useContext(StoreContext);
  const { openPopup } = useVodProviderPopup();

  return (
    <div className={`${styles.card} card`}>
      <DropZone position={position} />
      <DragZone id={id}>
        <DraggablePoster poster={poster} title={title} />
      </DragZone>
      <div className="card-body text-center">
        <div className={`${styles.genres} card-subtitle mb-2`}>
          <span className="align-middle text-uppercase">{type}</span>
          <span className={`${styles.bullet} align-middle`}> &#8226;  </span>
          {genres.map((genre, index) => {
            return (
              <Fragment
                key={genre}
              >
                <span className="text-nowrap align-middle text-uppercase">
                  {`${genre}${index < genres.length - 1 ? '/' : ''}`}
                </span>
                <span className="d-inline-block" />
              </Fragment>
            );
          })}
        </div>
        <h5 className={`${styles.title} card-title mb-0`}>{title}</h5>
      </div>
      <div className={`${styles.footer} card-footer text-center pt-0`}>
        <PurpleButton
          className="mb-3"
          onClick={() => {
            openPopup(id, title);
          }}
        >
          Where to Watch
        </PurpleButton>
        <DarkButton
          className="mb-2"
          onClick={() => {
            dispatch(actions.removeTrendingProduction(id));
          }}
        >
          Remove
        </DarkButton>
      </div>
    </div>
  );
};

export default TrendingProductionCard;
