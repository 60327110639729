import React, { useEffect, useContext, useState } from 'react';
import {
  NotificationBlock,
  NotificationForm,
  SquaredPurpleButton,
} from '../../components';

import styles from './create-notification.module.css';
import { StoreContext } from '../../store/store';
import Services from '../../services';
import { actions } from '../../store';
import { useAccessToken } from '../../hooks';

const CreateNotification: React.FC = () => {
  const {
    state: {
      notificationsList,
    },
    dispatch,
  } = useContext(StoreContext);

  const [
    sortedNotifications,
    setSortedNotifications,
  ] = useState<typeof notificationsList>([]);

  const accessToken = useAccessToken();

  useEffect(() => {
    setSortedNotifications([
      ...notificationsList.sort((first, second) => {
        const firstTimestamp = (new Date(first.releaseDate || 0)).getTime();
        const secondTimestamp = (new Date(second.releaseDate || 0)).getTime();

        return firstTimestamp - secondTimestamp;
      }),
    ]);
  }, [notificationsList]);

  return (
    <div className="row">
      <div className="col-xl-7 mb-5">
        <div className={`${styles.card} card`}>
          <div className="card-body">
            <NotificationForm
              onSubmit={async ({
                title,
                description,
                releaseDate,
                productionId,
                scheduled,
              }) => {
                const scheduledReleaseDate = scheduled
                  ? releaseDate && releaseDate.toISOString()
                  : null;
                const notificationData = {
                  title,
                  description,
                  releaseDate: scheduledReleaseDate,
                  productionId,
                };

                return Services.Notifications.save(
                  notificationData,
                  accessToken,
                ).then((newNotification) => {
                  if (!newNotification) return false;

                  dispatch(actions.finishCreatingNotification({
                    id: newNotification.id,
                    ...notificationData,
                  }));

                  return true;
                });
              }}
            >
              {({ errors, inputDisabled, scheduled }) => (
                <div className="form-group mb-0 d-flex justify-content-end">
                  <SquaredPurpleButton
                    className={`${styles.submitButton}`}
                    type="submit"
                    disabled={!!errors || inputDisabled}
                  >
                    {scheduled ? 'Schedule' : 'Send Now'}
                  </SquaredPurpleButton>
                </div>
              )}
            </NotificationForm>
          </div>
        </div>
      </div>
      <div className={`${styles.cardContainer} col-xl-5 mb-5`}>
        <div className={`${styles.card} card`}>
          <div className="card-body">
            <h4 className={`${styles.viewEventsHeader} card-title font-weight-bolder`}>
              Scheduled Notifications
            </h4>
            <div className="card-subtitle">
              {sortedNotifications.length > 0 ? (
                sortedNotifications.map((notification) => {
                  return (
                    <NotificationBlock
                      key={notification.id}
                      className={styles.eventDivider}
                      id={notification.id}
                      title={notification.title}
                      description={notification.description}
                      releaseDate={notification.releaseDate}
                    />
                  );
                })
              ) : (
                <NotificationBlock
                  className={styles.eventDivider}
                  title="No upcoming notifications right now!"
                  description="
                    Create notifications for the most
                    exciting news and controversials
                    around the world.
                  "
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
