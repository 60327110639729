import { Reducer } from 'react';
import { State, init } from './initial-state';
import * as actions from './actions';
import { mapAndMergeObjects } from '../utils/object-utils';
import ActionType from './action-types';
import { getUniqueArray } from '../utils/array-utils';

export type Actions = ReturnType<typeof actions[keyof typeof actions]>;
type CombinedReducer = Reducer<State, Actions>;
type ReducerFunctionWithActions<
  A extends ActionType,
  Actions,
> = Actions extends { type: A; payload: infer P } ?
  Reducer<State, { type: A; payload: P }> :
  Actions extends { type: A } ?
    Reducer<State, { type: A }> :
    never;
type ReducerFunction<A extends ActionType> = ReducerFunctionWithActions<A, Actions>;

const trendProduction: ReducerFunction<ActionType.TrendProduction> = (
  state,
  action,
) => {
  return {
    ...state,
    productionList: [
      ...state.productionList.filter(
        (productionId) => !state.trendingProductions.includes(productionId),
      ),
    ],
    trendingProductions: [
      action.payload,
      ...state.trendingProductions,
    ],
  };
};

const undoTrendProduction: ReducerFunction<ActionType.UndoTrendProduction> = (
  state,
  action,
) => {
  return {
    ...state,
    trendingProductions: [
      ...state.trendingProductions.filter((productionId) => productionId !== action.payload),
    ],
  };
};

const removeTrendingProduction: ReducerFunction<ActionType.RemoveTrendingProduction> = (
  state,
  action,
) => {
  return {
    ...state,
    productionList: getUniqueArray([
      ...state.productionList,
      action.payload,
    ]),
    trendingProductions: [
      ...state.trendingProductions.filter((productionId) => productionId !== action.payload),
    ],
  };
};

const changeTrendingProductionOrder: ReducerFunction<ActionType.ChangeTrendingProductionOrder> = (
  state,
  { payload },
) => {
  const leftSlice = state.trendingProductions.slice(0, payload.position);
  const insertedPosition = leftSlice.length;
  const rightSlice = state.trendingProductions.slice(insertedPosition);

  return {
    ...state,
    trendingProductions: [
      ...leftSlice,
      payload.productionId,
      ...rightSlice,
    ].filter(
      (productionId, index) => productionId !== payload.productionId || index === insertedPosition,
    ),
  };
};

const changeProductionsSearchQuery: ReducerFunction<ActionType.ChangeProductionsSearchQuery> = (
  state,
  { payload: query },
) => {
  return {
    ...state,
    productionSearchQuery: query,
    productionCurrentPage: 1,
  };
};

const startSearchingProductions: ReducerFunction<ActionType.StartSearchingProductions> = (
  state,
  { payload: { query, identifier } },
) => {
  if (query !== state.productionSearchQuery) return { ...state };

  return {
    ...state,
    productionFetchRequestIdentifier: identifier,
    searchingProductions: true,
    loadingMore: false,
  };
};

const finishSearchingProductions: ReducerFunction<ActionType.FinishSearchingProductions> = (
  state,
  { payload: { identifier, productions, page } },
) => {
  if (identifier !== state.productionFetchRequestIdentifier) return { ...state };

  return {
    ...state,
    searchingProductions: false,
    productionsData: {
      ...state.productionsData,
      ...mapAndMergeObjects(productions, (production) => [production.id, production]),
    },
    productionList: [
      ...productions.map((production) => production.id),
    ],
    productionCurrentPage: page,
  };
};

const startLoadingMoreProductions: ReducerFunction<ActionType.StartLoadingMoreProductions> = (
  state,
  { payload: { query, identifier } },
) => {
  if (query !== state.productionSearchQuery) return { ...state };

  return {
    ...state,
    productionFetchRequestIdentifier: identifier,
    loadingMore: true,
  };
};

const loadedMoreProductions: ReducerFunction<ActionType.LoadedMoreProductions> = (
  state,
  { payload: { identifier, productions, page } },
) => {
  if (identifier !== state.productionFetchRequestIdentifier) return { ...state };

  return {
    ...state,
    loadingMore: false,
    productionsData: {
      ...state.productionsData,
      ...mapAndMergeObjects(productions, (production) => [production.id, production]),
    },
    productionList: [
      ...state.productionList,
      ...productions.map((production) => production.id),
    ],
    productionCurrentPage: page,
  };
};

const loadedTrending: ReducerFunction<ActionType.LoadedTrending> = (
  state,
  { payload: productions },
) => {
  return {
    ...state,
    productionsData: {
      ...state.productionsData,
      ...mapAndMergeObjects(productions, (production) => [production.id, production]),
    },
    trendingProductions: [
      ...productions.map((production) => production.id),
    ],
    isTrendingLoaded: true,
  };
};

const searchTrending: ReducerFunction<ActionType.SearchTrending> = (
  state,
  { payload: query },
) => {
  return {
    ...state,
    trendingSearchQuery: query,
  };
};

const queueTrendingForSaving: ReducerFunction<ActionType.QueueTrendingForSaving> = (
  state,
) => {
  return {
    ...state,
    trendingProductionsPendingSaving: [
      ...state.trendingProductions,
    ],
  };
};

const startSavingTrending: ReducerFunction<ActionType.StartSavingTrending> = (
  state,
) => {
  if (
    state.trendingProductionsBeingSaved
    || !state.trendingProductionsPendingSaving
  ) return { ...state };

  return {
    ...state,
    trendingSaveFailed: false,
    trendingProductionsPendingSaving: null,
    trendingProductionsBeingSaved: [...state.trendingProductionsPendingSaving],
  };
};

const finishSavingTrending: ReducerFunction<ActionType.FinishSavingTrending> = (
  state,
  { payload: success },
) => {
  return {
    ...state,
    trendingProductionsBeingSaved: null,
    trendingSaveFailed: !success,
  };
};

const exitManageContent: ReducerFunction<ActionType.ExitManageContent> = () => {
  return {
    ...init(),
  };
};

const exitLiveEvents: ReducerFunction<ActionType.ExitLiveEvents> = () => {
  return {
    ...init(),
  };
};

const fetchedScheduledEvents: ReducerFunction<ActionType.FetchedScheduledEvents> = (
  state,
  { payload: scheduledEvents },
) => {
  return {
    ...state,
    scheduledEventsList: [
      ...scheduledEvents,
    ],
  };
};

const finishCreatingScheduledEvent: ReducerFunction<ActionType.FinishCreatingScheduledEvent> = (
  state,
  { payload: scheduledEvent },
) => {
  return {
    ...state,
    scheduledEventsList: [
      ...state.scheduledEventsList,
      {
        ...scheduledEvent,
      },
    ],
    newScheduledEvents: [
      ...state.newScheduledEvents,
      scheduledEvent.id,
    ],
  };
};

const finishEditingScheduledEvent: ReducerFunction<ActionType.FinishEditingScheduledEvent> = (
  state,
  { payload: editedScheduledEvent },
) => {
  return {
    ...state,
    scheduledEventsList: state.scheduledEventsList.map((scheduledEvent) => {
      return editedScheduledEvent.id === scheduledEvent.id
        ? { ...editedScheduledEvent }
        : { ...scheduledEvent };
    }),
  };
};

const finishDeletingScheduledEvent: ReducerFunction<ActionType.FinishDeletingScheduledEvent> = (
  state,
  { payload: deletedId },
) => {
  return {
    ...state,
    scheduledEventsList: state.scheduledEventsList.filter((scheduledEvent) => {
      return scheduledEvent.id !== deletedId;
    }),
  };
};

const markScheduledEventAsOld: ReducerFunction<ActionType.MarkScheduledEventAsOld> = (
  state,
  { payload: id },
) => {
  return {
    ...state,
    newScheduledEvents: state.newScheduledEvents.filter((scheduledEventId) => {
      return scheduledEventId !== id;
    }),
  };
};

const exitNotifications: ReducerFunction<ActionType.ExitNotifications> = () => {
  return {
    ...init(),
  };
};

const fetchedNotifications: ReducerFunction<ActionType.FetchedNotifications> = (
  state,
  { payload: notifications },
) => {
  return {
    ...state,
    notificationsList: [
      ...notifications,
    ],
  };
};

const finishCreatingNotification: ReducerFunction<ActionType.FinishCreatingNotification> = (
  state,
  { payload: notification },
) => {
  if (!notification.releaseDate) return { ...state };

  return {
    ...state,
    notificationsList: [
      ...state.notificationsList,
      {
        ...notification,
      },
    ],
    newNotifications: [
      ...state.newNotifications,
      notification.id,
    ],
  };
};

const finishEditingNotification: ReducerFunction<ActionType.FinishEditingNotification> = (
  state,
  { payload: editedNotification },
) => {
  return {
    ...state,
    notificationsList: state.notificationsList.map((notification) => {
      return editedNotification.id === notification.id
        ? { ...editedNotification }
        : { ...notification };
    }),
  };
};

const finishDeletingNotification: ReducerFunction<ActionType.FinishDeletingNotification> = (
  state,
  { payload: deletedId },
) => {
  return {
    ...state,
    notificationsList: state.notificationsList.filter((notification) => {
      return notification.id !== deletedId;
    }),
  };
};

const markNotificationAsOld: ReducerFunction<ActionType.MarkNotificationAsOld> = (
  state,
  { payload: id },
) => {
  return {
    ...state,
    newNotifications: state.newNotifications.filter((notificationId) => {
      return notificationId !== id;
    }),
  };
};

const reducer: CombinedReducer = (state, action) => {
  switch (action.type) {
    case ActionType.StartLoadingMoreProductions:
      return startLoadingMoreProductions(state, action);
    case ActionType.LoadedMoreProductions:
      return loadedMoreProductions(state, action);
    case ActionType.TrendProduction:
      return trendProduction(state, action);
    case ActionType.UndoTrendProduction:
      return undoTrendProduction(state, action);
    case ActionType.ChangeTrendingProductionOrder:
      return changeTrendingProductionOrder(state, action);
    case ActionType.RemoveTrendingProduction:
      return removeTrendingProduction(state, action);
    case ActionType.LoadedTrending:
      return loadedTrending(state, action);
    case ActionType.SearchTrending:
      return searchTrending(state, action);
    case ActionType.ChangeProductionsSearchQuery:
      return changeProductionsSearchQuery(state, action);
    case ActionType.StartSearchingProductions:
      return startSearchingProductions(state, action);
    case ActionType.FinishSearchingProductions:
      return finishSearchingProductions(state, action);
    case ActionType.QueueTrendingForSaving:
      return queueTrendingForSaving(state, action);
    case ActionType.StartSavingTrending:
      return startSavingTrending(state, action);
    case ActionType.FinishSavingTrending:
      return finishSavingTrending(state, action);
    case ActionType.ExitManageContent:
      return exitManageContent(state, action);
    case ActionType.FetchedScheduledEvents:
      return fetchedScheduledEvents(state, action);
    case ActionType.ExitLiveEvents:
      return exitLiveEvents(state, action);
    case ActionType.FinishCreatingScheduledEvent:
      return finishCreatingScheduledEvent(state, action);
    case ActionType.FinishEditingScheduledEvent:
      return finishEditingScheduledEvent(state, action);
    case ActionType.FinishDeletingScheduledEvent:
      return finishDeletingScheduledEvent(state, action);
    case ActionType.MarkScheduledEventAsOld:
      return markScheduledEventAsOld(state, action);
    case ActionType.ExitNotifications:
      return exitNotifications(state, action);
    case ActionType.FetchedNotifications:
      return fetchedNotifications(state, action);
    case ActionType.FinishCreatingNotification:
      return finishCreatingNotification(state, action);
    case ActionType.FinishEditingNotification:
      return finishEditingNotification(state, action);
    case ActionType.FinishDeletingNotification:
      return finishDeletingNotification(state, action);
    case ActionType.MarkNotificationAsOld:
      return markNotificationAsOld(state, action);
    default:
      return { ...state };
  }
};

export default reducer;
