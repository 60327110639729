import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { BasePickerProps } from '@material-ui/pickers/typings/BasePicker';
import { isValid, format } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import styles from './live-event-date-picker.module.css';

const formatDate: BasePickerProps['labelFunc'] = (date, invalidLabel) => {
  const dateClone = date && new Date(date.getTime());

  return dateClone && isValid(dateClone)
    ? format(dateClone, 'MMM d, y')
    : invalidLabel;
};

const LiveEventDatePicker: React.FC<{
  utcDate: Date | null;
  onDateChange: (date: Date | null) => void;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  timeZone?: string;
}> = ({
  utcDate,
  onDateChange,
  placeholder,
  required,
  disabled,
  timeZone,
}) => {
  const zonedDate = !timeZone || !utcDate
    ? utcDate
    : utcToZonedTime(utcDate, timeZone);
  return (
    <div className="d-flex flex-column">
      {/*
          TODO: There's a bug with material ui where the input
          isn't cleared, even though we are passing null, causing
          the placeholder to not be displayed.

          This workaround displays our own floating placeholder
          when null in the meantime, until their supposed fix in
          v4 of @material-ui/pickers.

          https://github.com/mui-org/material-ui-pickers/issues/1204#issuecomment-522553047
      */}
      <div className={styles.placeholder}>
        {!zonedDate && placeholder}
      </div>
      <DatePicker
        autoOk
        disableToolbar
        disablePast
        disabled={disabled}
        className={`${styles.dateInput} form-control`}
        InputProps={{
          classes: {
            root: styles.pickerRoot,
            input: `${styles.pickerInput} ${zonedDate ? '' : 'invisible'}`,
          },
        }}
        placeholder={placeholder}
        variant="inline"
        format="MM/dd/yyyy"
        value={zonedDate}
        onChange={(newDate) => {
          const utcNewDate = !timeZone || !newDate
            ? newDate
            : zonedTimeToUtc(newDate, timeZone);

          onDateChange(utcNewDate);
        }}
        labelFunc={formatDate}
        required={required}
      />
    </div>
  );
};

export default LiveEventDatePicker;
