import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import styles from './live-event-form.module.css';
import { DateUtils } from '../../utils';
import LiveEventDatePicker from './live-event-date-picker';
import LiveEventTimePicker from './live-event-time-picker';
import LiveEventFormTheme from './live-event-form-theme';
import { StoreContext } from '../../store/store';
import { formatTimezone } from '../../utils/date-utils';
import config from '../../config';

const compareEventTime = (
  first: Date | number,
  operator: DateUtils.DateComparisonOperator,
  second: Date | number,
) => {
  return DateUtils.compareTime(
    first,
    operator,
    second,
    {
      seconds: true,
      milliseconds: true,
    },
  );
};

const areDatesValid = (
  startDate: Date,
  endDate: Date,
) => {
  return compareEventTime(startDate, '<', endDate);
};

const LiveEventForm: React.FC<{
  defaultName?: string;
  defaultDescription?: string;
  defaultStartDate?: Date | null;
  defaultStartTime?: Date | null;
  defaultEndDate?: Date | null;
  defaultEndTime?: Date | null;
  onSubmit: (submission: {
    name: string;
    description: string;
    startDate: Date;
    endDate: Date;
  }) => Promise<boolean>;
  children: (
    errors: {
      collidingEvents: boolean | undefined;
    } | undefined,
    inputDisabled: boolean,
  ) => React.ReactNode;
  excludeCollision?: Array<number>;
  disabledForm?: boolean;
}> = ({
  defaultName = '',
  defaultDescription = '',
  defaultStartDate = null,
  defaultStartTime = null,
  defaultEndDate = null,
  defaultEndTime = null,
  onSubmit,
  children,
  excludeCollision = [],
  disabledForm,
}) => {
  const {
    state: {
      scheduledEventsList,
    },
  } = useContext(StoreContext);
  const [name, setName] = useState(defaultName);
  const [description, setDescription] = useState(defaultDescription);
  const [startTime, setStartTime] = useState<null | Date>(defaultStartTime);
  const [endTime, setEndTime] = useState<null | Date>(defaultEndTime);
  const [startDate, setStartDate] = useState<null | Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<null | Date>(defaultEndDate);
  const [submitting, setSubmitting] = useState(false);
  const [endDatePlaceholder, setEndDatePlaceholder] = useState('');

  useEffect(() => {
    if (endDate) return;

    const effectiveEndDate = getEffectiveEndDate();

    if (!effectiveEndDate) {
      setEndDatePlaceholder('');
    } else {
      setEndDatePlaceholder(format(effectiveEndDate, 'MMM d, y'));
    }
  }, [startDate, startTime, endTime, endDate]);

  const canInput = () => {
    return !submitting && !disabledForm;
  };

  const getStartDateTime = () => DateUtils.mergeLocaleTimeIntoDate(
    startDate,
    startTime,
    { time: { seconds: 0, milliseconds: 0 } },
  );

  const inferEndDate = () => {
    const effectiveEndDate = startDate
      ? new Date(startDate.getTime())
      : null;

    if (
      effectiveEndDate
      && startDate
      && startTime
      && endTime
      && compareEventTime(startTime, '>', endTime)
      && startDate.getDate() === effectiveEndDate.getDate()
    ) {
      effectiveEndDate.setDate(startDate.getDate() + 1);
    }

    return effectiveEndDate;
  };
  const getEffectiveEndDate = () => {
    return endDate
      ? new Date(endDate.getTime())
      : inferEndDate();
  };
  const getEndDateTime = () => DateUtils.mergeLocaleTimeIntoDate(
    getEffectiveEndDate(),
    endTime,
    { time: { seconds: 0, milliseconds: 0 } },
  );

  const resetForm = () => {
    setName('');
    setDescription('');
    setStartDate(null);
    setStartTime(null);
    setEndDate(null);
    setEndTime(null);
  };

  const collidingWithScheduledEvents = () => {
    const startDateTime = getStartDateTime();
    const endDateTime = getEndDateTime();

    if (!startDateTime || !endDateTime) return undefined;
    if (compareEventTime(startDateTime, '>=', endDateTime)) return undefined;

    return scheduledEventsList.some((scheduledEvent) => {
      return (
        !excludeCollision.includes(scheduledEvent.id)
        && compareEventTime(startDateTime, '<', scheduledEvent.endDateTimestamp)
        && compareEventTime(endDateTime, '>', scheduledEvent.startDateTimestamp)
      );
    });
  };

  const canSubmit = () => {
    const startDateTime = getStartDateTime();
    const endDateTime = getEndDateTime();

    return !!(
      !disabledForm
      && !submitting
      && name
      && description
      && startDateTime
      && endDateTime
      && compareEventTime(endDateTime, '>', Date.now())
      && compareEventTime(startDateTime, '<', endDateTime)
      && compareEventTime(startDateTime, '>', DateUtils.getLocaleStartOfToday())
      && areDatesValid(startDateTime, endDateTime)
      && collidingWithScheduledEvents() === false
    );
  };

  return (
    <LiveEventFormTheme>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          const startDateTime = getStartDateTime();
          const endDateTime = getEndDateTime();
          if (!startDateTime || !endDateTime) return;
          if (!canSubmit()) return;

          setSubmitting(true);
          onSubmit({
            name,
            description,
            startDate: startDateTime,
            endDate: endDateTime,
          }).then((success) => {
            setSubmitting(false);
            if (success) resetForm();
          });
        }}
      >
        <div className="form-group mb-4">
          <label className="font-weight-bolder">Name</label>
          <input
            type="text"
            className={`${styles.input} form-control`}
            placeholder={`${DateUtils.getLocaleNextYear()} Oscar's Ceremony`}
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            disabled={!canInput()}
          />
        </div>
        <div className="form-group mb-4">
          <label className="font-weight-bolder">Description</label>
          <textarea
            className={`${styles.description} ${styles.input} form-control`}
            placeholder="Invite the users to join!"
            maxLength={75}
            rows={3}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            required
            disabled={!canInput()}
          />
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-6 pr-2 mb-0">
            <label className="font-weight-bolder">Start date</label>
            <LiveEventDatePicker
              utcDate={startDate}
              onDateChange={setStartDate}
              placeholder="MM / DD / YYYY"
              required
              disabled={!canInput()}
              timeZone={config.timeZone}
            />
          </div>
          <div className="form-group col-6 pl-2 mb-0">
            <label className="font-weight-bolder">End date (optional)</label>
            <LiveEventDatePicker
              utcDate={endDate}
              onDateChange={setEndDate}
              placeholder={endDatePlaceholder || 'MM / DD / YYYY'}
              disabled={!canInput()}
              timeZone={config.timeZone}
            />
          </div>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-6 pr-2">
            <label className="font-weight-bolder">
              {`Start time (${formatTimezone(config.timeZone)})`}
            </label>
            <LiveEventTimePicker
              utcTime={startTime}
              onTimeChange={setStartTime}
              placeholder="08:00 AM"
              disabled={!canInput()}
              timeZone={config.timeZone}
            />
          </div>
          <div className="form-group col-6 pl-2">
            <label className="font-weight-bolder">
              {`End time (${formatTimezone(config.timeZone)})`}
            </label>
            <LiveEventTimePicker
              utcTime={endTime}
              onTimeChange={setEndTime}
              placeholder="08:00 PM"
              disabled={!canInput()}
              timeZone={config.timeZone}
            />
          </div>
        </div>
        {children(
          canSubmit() ? undefined : {
            collidingEvents: collidingWithScheduledEvents(),
          },
          !canInput(),
        )}
      </form>
    </LiveEventFormTheme>
  );
};

export default LiveEventForm;
