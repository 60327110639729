import React, { useContext, Fragment } from 'react';
import styles from './undo-production-card.module.css';
import { PurpleButton } from '..';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';

const UndoProductionCard: React.FC<{
  id: number;
  poster: string;
  type: string;
  genres: Array<string>;
  title: string;
}> = ({
  id,
  poster,
  type,
  genres,
  title,
}) => {
  const {
    dispatch,
  } = useContext(StoreContext);

  return (
    <div className={`${styles.card} card`}>
      <img
        className={`${styles.poster} card-img-top`}
        src={poster}
        alt={title}
      />
      <div className="card-body text-center">
        <div className={`${styles.genres} card-subtitle mb-2`}>
          <span className="align-middle text-uppercase">{type}</span>
          <span className={`${styles.bullet} align-middle`}> &#8226;  </span>
          {genres.map((genre, index) => {
            return (
              <Fragment
                key={genre}
              >
                <span className="text-nowrap align-middle text-uppercase">
                  {`${genre}${index < genres.length - 1 ? '/' : ''}`}
                </span>
                <span className="d-inline-block" />
              </Fragment>
            );
          })}
        </div>
        <h5 className={`${styles.title} card-title mb-0`}>{title}</h5>
      </div>
      <div className={`${styles.footer} card-footer text-center pt-0`}>
        <PurpleButton className="mb-2" onClick={() => dispatch(actions.undoTrendProduction(id))}>
          Undo
        </PurpleButton>
      </div>
    </div>
  );
};

export default UndoProductionCard;
