import { UnionToIntersection } from './types';

export const mergeObjects = <
  Item extends object,
  IntersectedElement extends UnionToIntersection<Item>,
>(...items: readonly Item[]): { [Key in keyof IntersectedElement]: IntersectedElement[Key] } => {
  return Object.assign({}, ...items);
};

// TODO: Fix "as" typings
export const mapObjectValues = <Collection extends object, Output extends unknown>(
  dictionary: Collection,
  mapper: (entry: [keyof Collection, Collection[keyof Collection]]) => Output,
): { [Key in keyof Collection]: Output } => {
  const mappedObject = Object.fromEntries(Object.entries(dictionary).map(
    ([key, value]) => {
      return [key, mapper([key as any, value])];
    },
  ));

  return mappedObject as { [Key in keyof Collection]: Output};
};

export const mapAndMergeObjects = <Item, Value>(
  items: Array<Item>,
  mapper: (item: Item) => [string | number, Value],
) => {
  return Object.fromEntries(items.map((item) => {
    return mapper(item);
  }));
};
