import React, { useState, useContext } from 'react';
import styles from './draggable-poster.module.css';
import Icons from '../../icons';
import { StoreContext } from '../../../store/store';

const DraggablePoster: React.FC<{
  poster: string;
  title: string;
}> = ({
  poster,
  title,
}) => {
  const { state: { trendingSearchQuery } } = useContext(StoreContext);
  const [isHovering, setHovering] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div
        className={`${styles.dragIcon} ${
          !!trendingSearchQuery || !isHovering ? styles.hide : ''
        }`}
      >
        <Icons.Drag />
      </div>
      <img
        className={`${styles.poster} card-img-top`}
        src={poster}
        alt={title}
      />
    </div>
  );
};

export default DraggablePoster;
