import { useEffect, useState } from 'react';
import useAccessToken from './use-access-token';
import { VodProviderModel } from '../services/vod-providers-service';
import Services from '../services';

type VodProviderItem = VodProviderModel & {
  selected: boolean;
};

type SaveStatus = undefined | 'Saving' | 'Failed' | 'Saved';

const useProductionVodProviders = (productionId?: number) => {
  const accessToken = useAccessToken();
  const [productionResult, setProductionResult] = useState<{
    productionId: number;
    data: VodProviderModel[];
  } | undefined>(undefined);
  const [allResult, setAllResult] = useState<{
    data: VodProviderModel[];
  } | undefined>(undefined);
  const [vodProviderList, setVodProviderList] = useState<VodProviderItem[] | undefined>(undefined);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(undefined);

  useEffect(() => {
    Services.VodProviders.getAll(accessToken).then((vodProviders) => {
      setAllResult({ data: vodProviders });
    });
  }, []);

  useEffect(() => {
    setSaveStatus(undefined);
    if (productionId) {
      Services.VodProviders.getByProduction(productionId, accessToken).then((vodProviders) => {
        setProductionResult({
          productionId,
          data: vodProviders,
        });
      });
    }
  }, [productionId]);

  useEffect(() => {
    if (!productionId) {
      setProductionResult(undefined);
    }

    setVodProviderList((
      productionResult
      && productionResult.productionId === productionId
      && allResult?.data.map((vodProvider) => {
        return {
          ...vodProvider,
          selected: !!productionResult.data.find((productionVodProvider) => {
            return productionVodProvider.id === vodProvider.id;
          }),
        };
      })
    ) || undefined);
  }, [productionResult, allResult, productionId]);

  const selectVodProvider = (vodProviderId: number, selected: boolean) => {
    const newVodProviderList = vodProviderList?.map((vodProvider) => {
      return {
        ...vodProvider,
        selected: vodProvider.id === vodProviderId
          ? selected
          : vodProvider.selected,
      };
    });

    setVodProviderList(newVodProviderList);
  };

  const save = () => {
    if (vodProviderList && productionId) {
      setSaveStatus('Saving');
      const vodProviderIds = vodProviderList.filter(
        (vodProvider) => vodProvider.selected,
      ).map(
        (vodProvider) => vodProvider.id,
      );
      Services.VodProviders.saveToProduction(
        productionId,
        vodProviderIds,
        accessToken,
      ).then((success) => {
        if (success) {
          setSaveStatus('Saved');
        } else {
          setSaveStatus('Failed');
        }
      });
    }
  };

  return [vodProviderList, selectVodProvider, save, saveStatus] as const;
};

export default useProductionVodProviders;
