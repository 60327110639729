import React from 'react';
import styles from './notification-edit-block.module.css';

const NotificationEditBlock: React.FC<{
  title: string;
  description: string;
  className?: string;
}> = ({
  title,
  description,
  className = '',
}) => {
  return (
    <div className={`${styles.block} ${className}`}>
      <div className={styles.arrow} />
      <div
        className={`
          ${styles.innerBlock} 
        `}
      >
        <div className={`${styles.title} text-wrap text-break`}>
          <span className="font-weight-bold">Edit mode: </span>
          <span>{title}</span>
        </div>
        <div className="text-wrap text-break">
          {description}
        </div>
      </div>
    </div>
  );
};

export default NotificationEditBlock;
