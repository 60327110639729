import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiClient, ApiCall } from './apis';

class BaseClient implements ApiClient {
  protected client: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.client = Axios.create({
      timeout: 30000,
      responseType: 'json',
      ...config,
    });
  }

  public get: ApiCall = async (path, parameters, headers) => {
    return this.client.get(path, {
      params: {
        ...parameters,
      },
      headers: {
        ...headers,
      },
    });
  };

  public post: ApiCall = async (path, parameters, headers) => {
    return this.client.post(
      path,
      {
        ...parameters,
      },
      {
        headers: {
          ...headers,
        },
      },
    );
  };

  public put: ApiCall = async (path, parameters, headers) => {
    return this.client.put(
      path,
      {
        ...parameters,
      },
      {
        headers: {
          ...headers,
        },
      },
    );
  };

  public patch: ApiCall = async (path, parameters, headers) => {
    return this.client.patch(
      path,
      {
        ...parameters,
      },
      {
        headers: {
          ...headers,
        },
      },
    );
  };

  public delete: ApiCall = async (path, parameters, headers) => {
    return this.client.delete(path, {
      params: {
        ...parameters,
      },
      data: {
        ...parameters,
      },
      headers: {
        ...headers,
      },
    });
  };
}

export default BaseClient;
