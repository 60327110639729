import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';
import { DragItemTypes } from '../../draggable-card-display';
import { StoreContext } from '../../../store/store';

const DragZone: React.FC<{
  id: number;
}> = ({
  children,
  id,
}) => {
  const { state: { trendingSearchQuery } } = useContext(StoreContext);
  const [, drag] = useDrag({
    item: {
      type: DragItemTypes.CARD,
      id,
    },
    canDrag: () => !trendingSearchQuery,
  });

  return (
    <div
      ref={drag}
    >
      {children}
    </div>
  );
};

export default DragZone;
