import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  ScheduledEventBlock,
  LiveEventForm,
  SquaredPurpleButton,
  ScheduledEventEditBlock,
  SquaredDarkButton,
  LiveEventDeleteModal,
} from '../../components';

import styles from './edit-event.module.css';
import { StoreContext } from '../../store/store';
import Services from '../../services';
import { actions } from '../../store';
import { Paths } from '../../routes';
import { useAccessToken } from '../../hooks';

const EditEvent: React.FC = () => {
  const {
    state: {
      scheduledEventsList,
    },
    dispatch,
  } = useContext(StoreContext);
  const history = useHistory();
  const { id: pathId } = useParams();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [
    sortedScheduledEvents,
    setSortedScheduledEvents,
  ] = useState<typeof scheduledEventsList>([]);
  const accessToken = useAccessToken();

  useEffect(() => {
    setSortedScheduledEvents([
      ...scheduledEventsList.sort((first, second) => {
        return first.startDateTimestamp - second.startDateTimestamp;
      }),
    ]);
  }, [scheduledEventsList]);

  const isFormDisabled = () => {
    return deleting;
  };

  const editingEventId = Number(pathId);
  const editingEvent = scheduledEventsList.find((scheduledEvent) => {
    return scheduledEvent.id === editingEventId;
  });

  return (
    <div className="row">
      <div className="col-xl-7 mb-5">
        <div className={`${styles.card} card`}>
          <div className="card-body">
            {editingEvent && (
              <>
                <LiveEventDeleteModal
                  liveEventId={editingEvent.id}
                  isOpen={openDeleteModal}
                  onClose={() => {
                    setDeleting(false);
                    setOpenDeleteModal(false);
                  }}
                  onDeleteFinish={(deletedId) => {
                    if (deletedId) {
                      setOpenDeleteModal(false);
                      dispatch(actions.finishDeletingScheduledEvent(deletedId));
                      history.push(Paths.CreateEvent);
                    }
                    setDeleting(false);
                  }}
                />
                <LiveEventForm
                  disabledForm={isFormDisabled()}
                  defaultName={editingEvent.name}
                  defaultDescription={editingEvent.description}
                  defaultStartDate={new Date(editingEvent.startDateTimestamp)}
                  defaultStartTime={new Date(editingEvent.startDateTimestamp)}
                  defaultEndDate={new Date(editingEvent.endDateTimestamp)}
                  defaultEndTime={new Date(editingEvent.endDateTimestamp)}
                  excludeCollision={[editingEventId]}
                  onSubmit={async ({
                    name,
                    description,
                    startDate,
                    endDate,
                  }) => {
                    return Services.LiveEvents.update({
                      id: editingEventId,
                      name,
                      description,
                      startDate,
                      endDate,
                    }, accessToken).then((editedId) => {
                      if (!editedId) return false;

                      dispatch(actions.finishEditingScheduledEvent({
                        id: editedId,
                        name,
                        description,
                        startDateTimestamp: startDate.getTime(),
                        endDateTimestamp: endDate.getTime(),
                      }));

                      history.push(Paths.CreateEvent);

                      return true;
                    });
                  }}
                >
                  {(errors, inputDisabled) => (
                    <div className="form-group mb-0 d-flex justify-content-end">
                      {errors?.collidingEvents === true && (
                        <div className="mr-3 align-self-center text-danger">
                          One or more events are colliding with the specified dates
                        </div>
                      )}
                      <SquaredDarkButton
                        className={`${styles.deleteButton}`}
                        type="button"
                        onClick={() => {
                          setDeleting(true);
                          setOpenDeleteModal(true);
                        }}
                        disabled={inputDisabled}
                      >
                        Delete
                      </SquaredDarkButton>
                      <SquaredPurpleButton
                        className={`${styles.submitButton}`}
                        type="submit"
                        disabled={!!errors || inputDisabled}
                      >
                        Save Changes
                      </SquaredPurpleButton>
                    </div>
                  )}
                </LiveEventForm>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.cardContainer} col-xl-5 mb-5`}>
        <div className={`${styles.card} card`}>
          <div className="card-body">
            <h4 className={`${styles.viewEventsHeader} card-title font-weight-bolder`}>
              Scheduled Events
            </h4>
            <div className="card-subtitle">
              {sortedScheduledEvents.map((scheduledEvent) => {
                return editingEventId === scheduledEvent.id ? (
                  <ScheduledEventEditBlock
                    key={scheduledEvent.id}
                    className={styles.selectedEventDivider}
                    name={scheduledEvent.name}
                    description={scheduledEvent.description}
                  />
                ) : (
                  <ScheduledEventBlock
                    key={scheduledEvent.id}
                    className={styles.eventDivider}
                    id={scheduledEvent.id}
                    name={scheduledEvent.name}
                    description={scheduledEvent.description}
                    startDate={scheduledEvent.startDateTimestamp}
                    endDate={scheduledEvent.endDateTimestamp}
                    disabled
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
