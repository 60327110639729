import React from 'react';
import './card-display.css';

const CardDisplay: React.FC = ({ children }) => {
  return (
    <div className="card-deck card-display justify-content-center">
      {children}
    </div>
  );
};

export default CardDisplay;
