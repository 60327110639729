import React, { useReducer } from 'react';
import { init, State } from './initial-state';
import reducer, { Actions } from './reducers';

export const StoreContext = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Actions>;
}>({ state: init(), dispatch: () => undefined });

const Store: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null, init);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export default Store;
