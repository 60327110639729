import React, { useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import styles from './notification-form.module.css';
import { DateUtils } from '../../utils';
import NotificationDatePicker from './notification-date-picker';
import NotificationTimePicker from './notification-time-picker';
import NotificationFormTheme from './notification-form-theme';
import { formatTimezone } from '../../utils/date-utils';
import config from '../../config';
import Icons from '../icons';
import NotificationProductionPicker from './notification-production-picker';
import Services from '../../services';
import { useAccessToken } from '../../hooks';
import NotificationInputHeader from './notification-input-header';
import NotificationConfirmationModal from './notification-confirmation-modal';

interface SelectedProduction {
  id: number;
  name: string;
  poster: string;
}

const TITLE_THRESHOLD = 32;
const DESCRIPTION_THRESHOLD = 133;
const DESCRIPTION_THRESHOLD_BANNER = 66;

const compareScheduledTime = (
  first: Date | number,
  operator: DateUtils.DateComparisonOperator,
  second: Date | number,
) => {
  return DateUtils.compareTime(
    first,
    operator,
    second,
    {
      seconds: true,
      milliseconds: true,
    },
  );
};

const NotificationForm: React.FC<{
  defaultTitle?: string;
  defaultDescription?: string;
  defaultReleaseDate?: Date | null;
  defaultReleaseTime?: Date | null;
  defaultProductionId?: number | null;
  onSubmit: (submission: {
    title: string;
    description: string;
    releaseDate: Date | null;
    productionId: number;
    scheduled: boolean;
  }) => Promise<boolean>;
  children: (properties: {
    errors: {} | undefined;
    scheduled: boolean;
    inputDisabled: boolean;
  }) => React.ReactNode;
  disabledForm?: boolean;
}> = ({
  defaultTitle = '',
  defaultDescription = '',
  defaultReleaseDate = null,
  defaultReleaseTime = null,
  defaultProductionId,
  onSubmit,
  children,
  disabledForm,
}) => {
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [scheduled, setScheduled] = useState(!!(defaultReleaseDate && defaultReleaseTime));
  const [releaseTime, setReleaseTime] = useState<null | Date>(defaultReleaseTime);
  const [releaseDate, setReleaseDate] = useState<null | Date>(defaultReleaseDate);
  const [submitting, setSubmitting] = useState(false);
  const [production, setProduction] = useState<SelectedProduction | null>(defaultProductionId
    ? {
      id: defaultProductionId,
      name: '',
      poster: '',
    } : null);
  const [openProduction, setOpenProduction] = useState(false);
  const accessToken = useAccessToken();
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    if (!defaultProductionId) return;

    Services.Productions.getById(defaultProductionId, accessToken).then((foundProduction) => {
      if (foundProduction && foundProduction.id === production?.id) {
        setProduction({
          id: foundProduction.id,
          name: foundProduction.title,
          poster: foundProduction.poster,
        });
      }
    });
  }, [defaultProductionId]);

  const canInput = () => {
    return !submitting && !disabledForm;
  };

  const getReleaseDateTime = () => DateUtils.mergeLocaleTimeIntoDate(
    releaseDate,
    releaseTime,
    { time: { seconds: 0, milliseconds: 0 } },
  );

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setReleaseDate(null);
    setReleaseTime(null);
    setScheduled(false);
    setProduction(null);
  };

  const canSubmit = () => {
    const releaseDateTime = getReleaseDateTime();

    return !!(
      !disabledForm
      && !submitting
      && title
      && description
      && production && production.id
      && (
        !scheduled
        || (
          releaseDateTime
          && compareScheduledTime(releaseDateTime, '>', DateUtils.getLocaleStartOfToday())
        )
      )
    );
  };

  const submitNotification = () => {
    const releaseDateTime = getReleaseDateTime();
    if (!canSubmit()) return;
    if (!production) return;

    setSubmitting(true);
    onSubmit({
      title,
      description,
      releaseDate: releaseDateTime,
      scheduled,
      productionId: production.id,
    }).then((success) => {
      setSubmitting(false);
      if (success) resetForm();
    });
  };

  return (
    <NotificationFormTheme>
      <NotificationConfirmationModal
        isOpen={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => {
          setOpenConfirmation(false);
          submitNotification();
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (scheduled) {
            submitNotification();
          } else {
            setOpenConfirmation(true);
          }
        }}
      >
        <div className="form-row">
          <div className="form-group col-8">
            <div className="form-group mb-4">
              <NotificationInputHeader
                input={title}
              >
                Title
                <span className="text-danger"> *</span>
              </NotificationInputHeader>
              <input
                type="text"
                className={`${styles.input} form-control`}
                placeholder={`${DateUtils.getLocaleNextYear()} Oscar's Ceremony`}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                required
                disabled={!canInput()}
              />
              {title.length > TITLE_THRESHOLD && (
                <div className="my-1 mx-3 align-self-center text-danger">
                  Title contains too many characters,
                  and might not display properly on our users' devices
                </div>
              )}
            </div>
            <div className="form-group mb-4">
              <NotificationInputHeader
                input={description}
              >
                Description
              </NotificationInputHeader>
              <textarea
                className={`${styles.description} ${styles.input} form-control`}
                placeholder="Invite the users to join!"
                maxLength={200}
                rows={3}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                required
                disabled={!canInput()}
              />
              {description.length > DESCRIPTION_THRESHOLD ? (
                <div className="my-1 mx-3 align-self-center text-danger">
                  Description contains too many characters,
                  and might not display properly on our users' devices
                </div>
              ) : description.length > DESCRIPTION_THRESHOLD_BANNER && (
                <div className="my-1 mx-3 align-self-center text-danger">
                  Description might contain too many characters,
                  and might not display properly as a notification banner
                </div>
              )}
            </div>
            <div className="form-row">
              <div className={`form-group col ${styles.scheduleGroup}`}>
                <Checkbox
                  classes={{
                    root: styles.checkboxRoot,
                  }}
                  checked={scheduled}
                  onChange={(_, checked) => {
                    setScheduled(checked);
                  }}
                  disabled={!canInput()}
                />
                <span className="font-weight-bolder">Schedule</span>
              </div>
            </div>
            {scheduled && (
              <div className="form-row mb-4">
                <div className="form-group col-6 pr-2 mb-0">
                  <label className="font-weight-bolder">Release date</label>
                  <NotificationDatePicker
                    utcDate={releaseDate}
                    onDateChange={setReleaseDate}
                    placeholder="MM / DD / YYYY"
                    required
                    disabled={!canInput()}
                    timeZone={config.timeZone}
                  />
                </div>
                <div className="form-group col-6 pl-2 mb-0">
                  <label className="font-weight-bolder">
                    {`Time (${formatTimezone(config.timeZone)})`}
                  </label>
                  <NotificationTimePicker
                    utcTime={releaseTime}
                    onTimeChange={setReleaseTime}
                    placeholder="08:00 AM"
                    disabled={!canInput()}
                    timeZone={config.timeZone}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={`form-group col-4 ${styles.tag}`}>
            <label className="font-weight-bolder">Tag production</label>
            <div
              className={styles.tagBox}
              style={{
                backgroundImage: production && production.poster
                  ? `url(${production.poster})`
                  : 'none',
              }}
            >
              <button
                type="button"
                className={styles.tagOverlay}
                disabled={!canInput()}
                onClick={() => setOpenProduction(true)}
              >
                <Icons.Add className={`${styles.add} ${production ? styles.hidden : ''}`} />
                <div className={styles.addButton}>
                  {production ? 'Change' : 'Add'}
                </div>
              </button>
            </div>
          </div>
        </div>
        {children({
          errors: canSubmit() ? undefined : {},
          inputDisabled: !canInput(),
          scheduled,
        })}
      </form>
      <NotificationProductionPicker
        open={openProduction}
        onClose={() => setOpenProduction(false)}
        onClickAdd={(selectedProduction) => {
          setProduction(selectedProduction);
          setOpenProduction(false);
        }}
      />
    </NotificationFormTheme>
  );
};

export default NotificationForm;
