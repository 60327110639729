import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Pages from '../pages';
import * as Paths from './paths';
import { PrivateRoute } from '../components';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={Paths.Home}>
        <Redirect to={Paths.Auth} />
      </Route>
      <Route path={Paths.Auth}>
        <Pages.Auth />
      </Route>
      <PrivateRoute path={Paths.Manage}>
        <Pages.ManageContent />
      </PrivateRoute>
      <PrivateRoute path={Paths.LiveEvents}>
        <Pages.LiveEvents />
      </PrivateRoute>
      <PrivateRoute path={Paths.Notifications}>
        <Pages.Notifications />
      </PrivateRoute>
      <Route path={Paths.NotFound}>
        <Pages.NotFound />
      </Route>
      <Route path={Paths.Default}>
        <Redirect to={Paths.NotFound} />
      </Route>
    </Switch>
  );
};

export default Routes;
