import { AdminApi } from './apis';
import { ApiResponse } from './apis/apis';

type ProductionModel = {
  id: number;
  poster: string;
  type: string;
  genres: Array<string>;
  title: string;
};

type ApiProductionModel = {
  id: number;
  image: string;
  title: string;
  genres: Array<string>;
  overview: string;
  type: ProductionType;
};

enum ProductionType {
  Movie = 1,
  Show = 2,
}

class ProductionsService {
  public static search = async (
    query: string,
    options: {
      currentPage: number;
      exclude: Array<number>;
      count: number;
      maxPageSearches: number;
    },
    accessToken: string,
  ): Promise<{
    results: Array<ProductionModel>;
    page: number;
  }> => {
    let foundProductions: Array<ProductionModel> = [];
    let lastSearchedPage = options.currentPage;
    let searchPage = lastSearchedPage;

    try {
      while (
        foundProductions.length < options.count
        && lastSearchedPage - options.currentPage < options.maxPageSearches
      ) {
        const response: ApiResponse<{
          results: Array<ApiProductionModel>;
          page: string;
        }> = await AdminApi.searchProductions({
          request: {
            query,
            page: searchPage,
          },
          headers: {
            Authorization: accessToken,
          },
        });

        if (!response) break;

        const productionResults = (response.data.results.map((production) => ({
          id: production.id,
          poster: production.image,
          type: production.type === ProductionType.Movie ? 'Movie' : 'Show',
          genres: [...production.genres],
          title: production.title,
        })) || []).filter((production) => {
          return !options.exclude.includes(production.id);
        });

        foundProductions = [...foundProductions, ...productionResults];
        lastSearchedPage = Number(response.data.page);
        searchPage = lastSearchedPage + 1;

        /*
          TODO: This is needed as a workaround for detecting when
          there are no more search results, as the metadata provider currently
          doesn't provide information such as "results per page" or "total pages".
          And a change to the metadata provider to provide this information breaks
          the current abstraction design, so therefore we need to
          coordinate this change at a later date.

          For now, we know that our current provider provides 20 results per page,
          unless it has reached the end of search.
        */
        if (response.data.results.length < 20) break;
      }
    } catch (err) {
      foundProductions = [];
      lastSearchedPage = options.currentPage;
    }

    return {
      results: foundProductions.slice(0, options.count),
      page: lastSearchedPage,
    };
  };

  public static getTrending = async (accessToken: string) => {
    try {
      const response: ApiResponse<Array<ApiProductionModel>> = await AdminApi.getTrending({
        headers: {
          Authorization: accessToken,
        },
      });

      const trendingProductions = response?.data.map((production) => ({
        id: production.id,
        poster: production.image,
        type: production.type === ProductionType.Movie ? 'Movie' : 'Show',
        genres: [...production.genres],
        title: production.title,
      })) || [];

      return trendingProductions;
    } catch (err) {
      return null;
    }
  };

  public static getById = async (productionId: number, accessToken: string) => {
    try {
      const response: ApiResponse<ApiProductionModel> = await AdminApi.getProductionById({
        path: {
          productionId,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      if (!response) return null;

      return {
        id: response.data.id,
        poster: response.data.image,
        type: response.data.type === ProductionType.Movie ? 'Movie' : 'Show',
        genres: [...response.data.genres],
        title: response.data.title,
      };
    } catch (err) {
      return null;
    }
  };

  public static saveTrending = async (productionIds: Array<number>, accessToken: string) => {
    try {
      await AdminApi.saveTrending({
        request: {
          productionIds,
        },
        headers: {
          Authorization: accessToken,
        },
      });

      return true;
    } catch (err) {
      return false;
    }
  };
}

export default ProductionsService;
