import React from 'react';
import UndoProductionCard from '../undo-production-card';
import AddProductionCard from '../add-production-card';

const ProductionCard: React.FC<{
  id: number;
  poster: string;
  type: string;
  genres: Array<string>;
  title: string;
  trending?: boolean;
}> = ({
  id,
  poster,
  type,
  genres,
  title,
  trending,
}) => {
  return trending ? (
    <UndoProductionCard
      id={id}
      poster={poster}
      type={type}
      genres={[...genres]}
      title={title}
    />
  ) : (
    <AddProductionCard
      id={id}
      poster={poster}
      type={type}
      genres={[...genres]}
      title={title}
    />
  );
};

export default ProductionCard;
