import React from 'react';
import styles from './search-bar.module.css';
import Icons from '../icons';

const SearchBar: React.FC<{
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}> = ({
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className={`${styles.searchBar} input-group align-items-center border rounded-pill`}>
      <div className="input-group-prepend border-0">
        <button className={`${styles.iconButton} btn btn-link text-dark py-0 pl-3 pr-0`} type="button">
          <Icons.Search />
        </button>
      </div>
      <input
        type="search"
        className={`${styles.input} form-control bg-transparent border-0`}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          onChange?.(event.target.value);
        }}
      />
    </div>
  );
};

export default SearchBar;
