import React from 'react';
import styles from './drag-icon.module.css';
import { ReactComponent as DragAsset } from '../../../assets/icons/drag.svg';

const DragIcon: React.FC = () => {
  return (
    <div className={`${styles.dragIcon}`}>
      <DragAsset />
    </div>
  );
};

export default DragIcon;
