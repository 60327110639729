import React, { useEffect, useContext, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import {
  TopHeaderBar,
  Icons,
} from '../../components';
import styles from './notifications.module.css';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';
import Services from '../../services';
import { Paths } from '../../routes';
import Pages from '..';
import { useAccessToken } from '../../hooks';

const Notifications: React.FC = () => {
  const {
    dispatch,
  } = useContext(StoreContext);

  const [finishedLoading, setFinishedLoading] = useState(false);
  const accessToken = useAccessToken();

  useEffect(() => {
    Services.Notifications.getScheduled(accessToken).then((notifications) => {
      dispatch(actions.fetchedNotifications(notifications));
      setFinishedLoading(true);
    });

    return () => {
      dispatch(actions.exitNotifications());
    };
  }, []);

  return (
    <div>
      <TopHeaderBar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            <Switch>
              <Route path={Paths.CreateNotification}>
                <div className="row mb-3">
                  <div className="col">
                    <span className={styles.title}>New Notification</span>
                  </div>
                </div>
                {finishedLoading ? (
                  <Pages.CreateNotification />
                ) : (
                  <Icons.Loading className={`${styles.loading}`} />
                )}
              </Route>
              <Route path={Paths.EditNotification}>
                <div className="row mb-3">
                  <div className="col">
                    <span className={styles.title}>Edit Notification</span>
                  </div>
                </div>
                {finishedLoading ? (
                  <Pages.EditNotification />
                ) : (
                  <Icons.Loading className={`${styles.loading}`} />
                )}
              </Route>
              <Route exact path={Paths.Notifications}>
                <Redirect to={Paths.CreateNotification} />
              </Route>
              <Route path={Paths.Default}>
                <Redirect to={Paths.NotFound} />
              </Route>
            </Switch>
          </div>
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
