import React from 'react';
import styles from './squared-purple-button.module.css';
import PurpleButton from '../purple-button';

const SquaredPurpleButton: React.FC<{
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
}> = ({
  className = '',
  disabled,
  onClick,
  type,
  children,
}) => {
  return (
    <PurpleButton
      type={type}
      className={`${className} ${styles.squared}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </PurpleButton>
  );
};

export default SquaredPurpleButton;
