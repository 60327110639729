import React, { useState } from 'react';
import {
  Link,
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom';
import styles from './verify-reset-token-form.module.css';
import Services from '../../services';
import { Paths } from '../../routes';
import PurpleButton from '../purple-button';

const VerifyResetTokenForm: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [resetToken, setResetToken] = useState('');

  const history = useHistory();
  const location = useLocation<{ email?: string } | null | undefined>();
  const email = location.state?.email;

  return !email ? (
    <Redirect to={Paths.ForgotPassword} />
  ) : (
    <form
      className={`${styles.form}`}
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitting(true);
        Services.Administrators.verifyResetToken({
          email,
          resetToken,
        }).then((success) => {
          if (success) {
            history.push({
              pathname: Paths.ResetPassword,
              state: {
                email,
                resetToken,
              },
            });
          }
          setSubmitting(false);
        });
      }}
    >
      <div className="form-group mb-4">
        <label className="font-weight-bolder">Email Address</label>
        <input
          type="email"
          className={`${styles.input} form-control`}
          value={email}
          disabled
          required
        />
      </div>
      <div className="form-group mb-4">
        <label className="font-weight-bolder">Reset Code</label>
        <input
          type="text"
          className={`${styles.input} form-control`}
          value={resetToken}
          onChange={(e) => setResetToken(e.target.value)}
          disabled={submitting}
          required
        />
      </div>
      <div className="form-group mb-0 d-flex justify-content-between align-items-center">
        <Link className={styles.signin} to={Paths.Login}>
          Sign in
        </Link>
        <PurpleButton
          className={`${styles.submitButton}`}
          type="submit"
          disabled={submitting}
        >
          Confirm
        </PurpleButton>
      </div>
    </form>
  );
};

export default VerifyResetTokenForm;
