import React from 'react';
import { Dialog } from '@material-ui/core';
import styles from './notification-confirmation-modal.module.css';
import Icons from '../../icons';
import SquaredDarkButton from '../../squared-dark-button';
import SquaredPurpleButton from '../../squared-purple-button';

const NotificationConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      onClose={() => onClose()}
      open={isOpen}
      BackdropProps={{
        classes: {
          root: styles.backdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: `${styles.card} card`,
        },
      }}
    >
      <div className="container-fluid p-0">
        <div className="row mb-3">
          <div className={`${styles.header} col-12 d-flex justify-content-between align-items-center`}>
            <div className={styles.title}>
              Are you sure?
            </div>
            <button
              type="button"
              className={styles.closeButton}
              onClick={() => onClose()}
            >
              <Icons.CloseX />
            </button>
          </div>
        </div>
        <div className={`${styles.body} row mb-3`}>
          <div className="col-12">
            "With great power, there must also come great responsibility."
            All of PopViewers's users will receive this notification.
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <SquaredDarkButton
              className={`${styles.cancelButton}`}
              type="button"
              onClick={onClose}
            >
              Cancel
            </SquaredDarkButton>
            <SquaredPurpleButton
              className={`${styles.sendButton}`}
              type="button"
              onClick={onConfirm}
            >
              Send Now
            </SquaredPurpleButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NotificationConfirmationModal;
