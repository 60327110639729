import React, { useContext, useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import styles from './notification-production-picker.module.css';
import { StoreContext } from '../../../store/store';
import { useAccessToken } from '../../../hooks';
import ProductionSearchBar from '../../production-search-bar';
import Icons from '../../icons';
import CardDisplay from '../../card-display';
import NotificationProductionCard from '../notification-production-card';
import DarkButton from '../../dark-button';
import { getRandomInt } from '../../../utils/number-utils';
import { actions } from '../../../store';
import Services from '../../../services';

const NotificationProductionPicker: React.FC<{
  onClose: () => void;
  open: boolean;
  onClickAdd: (production: {
    id: number;
    name: string;
    poster: string;
  }) => void;
}> = ({ onClose, open, onClickAdd }) => {
  const {
    state: {
      productionsData,
      productionList,
      searchingProductions,
      productionSearchQuery,
      loadingMore,
      productionCurrentPage,
    },
    dispatch,
  } = useContext(StoreContext);
  const accessToken = useAccessToken();
  const [timeoutId, setTimeoutId] = useState(0);

  useEffect(() => {
    window.clearTimeout(timeoutId);
    const identifier = `${getRandomInt()}_search_${productionSearchQuery}`;
    dispatch(actions.startSearchingProductions(productionSearchQuery, identifier));
    setTimeoutId(window.setTimeout(() => {
      Services.Productions.search(
        productionSearchQuery,
        {
          currentPage: productionCurrentPage,
          exclude: [],
          count: 12,
          maxPageSearches: 10,
        },
        accessToken,
      ).then(({ results, page }) => {
        dispatch(actions.finishSearchingProductions(
          identifier,
          results,
          page,
        ));
      }).catch(() => {});
    }, 1000));
  }, [productionSearchQuery, dispatch]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        classes: {
          root: `${styles.dialog} card`,
        },
      }}
    >
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center pb-5">
          <div className={`col-11 d-flex ${styles.title}`}>
            Tag movie or show
          </div>
          <button
            className={`col-1 d-flex ${styles.closeButton}`}
            type="button"
            onClick={onClose}
          >
            <Icons.CloseX />
          </button>
        </div>
        <div className="row justify-content-between align-items-center pb-5">
          <div className="col d-flex">
            <ProductionSearchBar />
          </div>
        </div>
        <div className="row mb-3">
          <div className={`col ${styles.body}`}>
            {searchingProductions ? (
              <Icons.Searching className={`${styles.searching}`} />
            ) : (
              <CardDisplay>
                {productionList.map((productionId) => {
                  return (
                    <NotificationProductionCard
                      key={productionsData[productionId].id}
                      id={productionsData[productionId].id}
                      poster={productionsData[productionId].poster}
                      type={productionsData[productionId].type}
                      genres={[...productionsData[productionId].genres]}
                      title={productionsData[productionId].title}
                      onClickAdd={onClickAdd}
                    />
                  );
                })}
              </CardDisplay>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-auto">
            {!searchingProductions && (
              <DarkButton
                disabled={searchingProductions || loadingMore}
                onClick={() => {
                  const identifier = `${getRandomInt()}_load-more_${productionSearchQuery}`;
                  dispatch(actions.startLoadingMoreProductions(productionSearchQuery, identifier));
                  Services.Productions.search(
                    productionSearchQuery,
                    {
                      currentPage: productionCurrentPage,
                      exclude: [...productionList],
                      count: 12,
                      maxPageSearches: 10,
                    },
                    accessToken,
                  ).then(({ results, page }) => {
                    dispatch(actions.loadedMoreProductions(
                      identifier,
                      results,
                      page,
                    ));
                  }).catch(() => {});
                }}
              >
                Load more
              </DarkButton>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NotificationProductionPicker;
