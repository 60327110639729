import React from 'react';
import { DndProvider } from 'react-dnd';
import CardDisplay from '../card-display';
import { getDnDBackend } from '../../utils/drag-and-drop';

const DraggableCardDisplay: React.FC = ({ children }) => {
  return (
    <DndProvider backend={getDnDBackend()}>
      <CardDisplay>
        {children}
      </CardDisplay>
    </DndProvider>
  );
};

export default DraggableCardDisplay;
