import React from 'react';
import { Dialog, Checkbox, CircularProgress } from '@material-ui/core';
import styles from './vod-provider-modal.module.css';
import useVodProviderPopup from '../../hooks/use-vod-provider-popup';
import { useProductionVodProviders } from '../../hooks';
import PurpleButton from '../purple-button';
import DarkButton from '../dark-button';

const VodProviderModal: React.FC = () => {
  const { production, open, closePopup } = useVodProviderPopup();
  const [
    vodProviders,
    selectVodProvider,
    saveVodProviders,
    saveStatus,
  ] = useProductionVodProviders(production?.productionId);

  return (
    <Dialog
      onClose={closePopup}
      open={open}
      PaperProps={{
        classes: {
          root: `${styles.dialog} card`,
        },
      }}
    >
      <div className={styles.title}>
        {production?.productionName}
      </div>
      <div className={styles.vodProviderList}>
        {vodProviders?.map((vodProvider) => (
          <div className={styles.vodProviderItem}>
            <span className={styles.vodProviderName}>{vodProvider.name}</span>
            <Checkbox
              checked={vodProvider.selected}
              onChange={(_, checked) => {
                selectVodProvider(vodProvider.id, checked);
              }}
            />
          </div>
        )) || (
          <div className={styles.spinner}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </div>
      <div className={styles.controls}>
        <span className={styles.saveStatus}>{saveStatus}</span>
        <DarkButton onClick={closePopup} disabled={saveStatus === 'Saving'}>
          Cancel
        </DarkButton>
        <PurpleButton
          className="ml-3"
          disabled={!vodProviders || saveStatus === 'Saving'}
          onClick={() => {
            saveVodProviders();
          }}
        >
          Save
        </PurpleButton>
      </div>
    </Dialog>
  );
};

export default VodProviderModal;
