import { Dictionary } from '../utils/types';

export interface Production {
  id: number;
  poster: string;
  type: string;
  genres: Array<string>;
  title: string;
}

export interface ScheduledEvent {
  id: number;
  name: string;
  description: string;
  startDateTimestamp: number;
  endDateTimestamp: number;
}

export interface Notification {
  id: number;
  title: string;
  description: string;
  releaseDate: string | null | undefined;
  productionId: number;
}

export interface State {
  productionsData: Dictionary<Production>;
  productionList: Array<number>;
  productionSearchQuery: string;
  productionFetchRequestIdentifier: string;
  productionCurrentPage: number;
  searchingProductions: boolean;
  loadingMore: boolean;
  trendingProductions: Array<number>;
  trendingProductionsBeingSaved: Array<number> | null;
  trendingProductionsPendingSaving: Array<number> | null;
  trendingSaveFailed: boolean;
  trendingSearchQuery: string;
  isTrendingLoaded: boolean;
  scheduledEventsList: Array<ScheduledEvent>;
  newScheduledEvents: Array<number>;
  notificationsList: Notification[];
  newNotifications: number[];
}

export const init = (): State => ({
  productionsData: {},
  productionList: [],
  productionSearchQuery: '',
  productionFetchRequestIdentifier: '',
  productionCurrentPage: 1,
  searchingProductions: false,
  loadingMore: false,
  trendingProductions: [],
  trendingProductionsBeingSaved: null,
  trendingProductionsPendingSaving: null,
  trendingSaveFailed: false,
  trendingSearchQuery: '',
  isTrendingLoaded: false,
  scheduledEventsList: [],
  newScheduledEvents: [],
  notificationsList: [],
  newNotifications: [],
});
