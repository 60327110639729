import React, { useEffect, useContext, useState } from 'react';
import {
  ScheduledEventBlock,
  LiveEventForm,
  SquaredPurpleButton,
} from '../../components';

import styles from './create-event.module.css';
import { StoreContext } from '../../store/store';
import Services from '../../services';
import { actions } from '../../store';
import { useAccessToken } from '../../hooks';

const CreateEvent: React.FC = () => {
  const {
    state: {
      scheduledEventsList,
    },
    dispatch,
  } = useContext(StoreContext);

  const [
    sortedScheduledEvents,
    setSortedScheduledEvents,
  ] = useState<typeof scheduledEventsList>([]);

  const accessToken = useAccessToken();

  useEffect(() => {
    setSortedScheduledEvents([
      ...scheduledEventsList.sort((first, second) => {
        return first.startDateTimestamp - second.startDateTimestamp;
      }),
    ]);
  }, [scheduledEventsList]);

  return (
    <div className="row">
      <div className="col-xl-7 mb-5">
        <div className={`${styles.card} card`}>
          <div className="card-body">
            <LiveEventForm
              onSubmit={async ({
                name,
                description,
                startDate,
                endDate,
              }) => {
                return Services.LiveEvents.save({
                  name,
                  description,
                  startDate,
                  endDate,
                }, accessToken).then((newId) => {
                  if (!newId) return false;

                  dispatch(actions.finishCreatingScheduledEvent({
                    id: newId,
                    name,
                    description,
                    startDateTimestamp: startDate.getTime(),
                    endDateTimestamp: endDate.getTime(),
                  }));

                  return true;
                });
              }}
            >
              {(errors, inputDisabled) => (
                <div className="form-group mb-0 d-flex justify-content-end">
                  {errors?.collidingEvents === true && (
                    <div className="mr-3 align-self-center text-danger">
                      One or more events are colliding with the specified dates
                    </div>
                  )}
                  <SquaredPurpleButton
                    className={`${styles.submitButton}`}
                    type="submit"
                    disabled={!!errors || inputDisabled}
                  >
                    Add Event
                  </SquaredPurpleButton>
                </div>
              )}
            </LiveEventForm>
          </div>
        </div>
      </div>
      <div className={`${styles.cardContainer} col-xl-5 mb-5`}>
        <div className={`${styles.card} card`}>
          <div className="card-body">
            <h4 className={`${styles.viewEventsHeader} card-title font-weight-bolder`}>
              Scheduled Events
            </h4>
            <div className="card-subtitle">
              {sortedScheduledEvents.length > 0 ? (
                sortedScheduledEvents.map((scheduledEvent) => {
                  return (
                    <ScheduledEventBlock
                      key={scheduledEvent.id}
                      className={styles.eventDivider}
                      id={scheduledEvent.id}
                      name={scheduledEvent.name}
                      description={scheduledEvent.description}
                      startDate={scheduledEvent.startDateTimestamp}
                      endDate={scheduledEvent.endDateTimestamp}
                    />
                  );
                })
              ) : (
                <ScheduledEventBlock
                  className={styles.eventDivider}
                  name="No upcoming events right now!"
                  description="
                    Create events for the most
                    exciting upcoming productions and ceremonies
                    around the world.
                  "
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
