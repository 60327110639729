export default Object.freeze({
  searchProductions: {
    method: 'get',
    path: '/productions',
  },
  getProductionById: {
    method: 'get',
    path: '/productions/:productionId',
  },
  getTrending: {
    method: 'get',
    path: '/productions/trending',
  },
  saveTrending: {
    method: 'put',
    path: '/productions/trending',
  },
  saveLiveEvent: {
    method: 'post',
    path: '/live-events',
  },
  updateLiveEvent: {
    method: 'put',
    path: '/live-events/:id',
  },
  deleteLiveEvent: {
    method: 'delete',
    path: '/live-events/:id',
  },
  getScheduledEvents: {
    method: 'get',
    path: '/live-events/scheduled',
  },
  login: {
    method: 'post',
    path: '/administrators/login',
  },
  logout: {
    method: 'post',
    path: '/administrators/logout',
  },
  verifySession: {
    method: 'post',
    path: '/administrators/session',
  },
  forgotPassword: {
    method: 'post',
    path: '/administrators/forgot-password',
  },
  verifyResetToken: {
    method: 'post',
    path: '/administrators/forgot-password/verify',
  },
  resetPassword: {
    method: 'post',
    path: '/administrators/reset-password',
  },
  getVodProviders: {
    method: 'get',
    path: '/vod-providers',
  },
  getVodProvidersByProduction: {
    method: 'get',
    path: '/productions/:productionId/vod-providers',
  },
  saveVodProvidersToProduction: {
    method: 'post',
    path: '/productions/:productionId/vod-providers',
  },
  getScheduledNotifications: {
    method: 'get',
    path: '/notifications/scheduled',
  },
  getNotificationById: {
    method: 'get',
    path: '/notifications/:notificationId',
  },
  saveNotification: {
    method: 'post',
    path: '/notifications',
  },
  updateNotification: {
    method: 'put',
    path: '/notifications/:notificationId',
  },
  deleteNotification: {
    method: 'delete',
    path: '/notifications/:notificationId',
  },
} as const);
