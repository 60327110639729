import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import styles from './live-event-delete-modal.module.css';
import SquaredRedButton from '../squared-red-button';
import SquaredPurpleButton from '../squared-purple-button';
import Services from '../../services';
import Icons from '../icons';
import { useAccessToken } from '../../hooks';

const LiveEventDeleteModal: React.FC<{
  liveEventId: number;
  isOpen: boolean;
  onClose: () => void;
  onDeleteFinish: (deletedId?: number) => void;
}> = ({
  liveEventId,
  isOpen,
  onClose,
  onDeleteFinish,
}) => {
  const [deleting, setDeleting] = useState(false);
  const accessToken = useAccessToken();

  return (
    <Dialog
      onClose={() => onClose()}
      open={isOpen}
      BackdropProps={{
        classes: {
          root: styles.backdrop,
        },
      }}
      PaperProps={{
        classes: {
          root: `${styles.card} card`,
        },
      }}
    >
      <div className="container-fluid p-0">
        <div className="row mb-3">
          <div className={`${styles.header} col-12 d-flex justify-content-between align-items-center`}>
            <div className={styles.title}>
              Are you sure?
            </div>
            <button
              type="button"
              className={styles.closeButton}
              onClick={() => onClose()}
            >
              <Icons.CloseX />
            </button>
          </div>
        </div>
        <div className={`${styles.body} row mb-3`}>
          <div className="col-12">Do you really want to delete this event?</div>
          <div className="col-12 font-weight-bold">This action cannot be undone!</div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <SquaredRedButton
              className={`${styles.deleteButton}`}
              type="button"
              onClick={() => {
                setDeleting(true);
                Services.LiveEvents.delete(liveEventId, accessToken).then((deletedId) => {
                  onDeleteFinish(deletedId);
                  setDeleting(false);
                });
              }}
              disabled={deleting}
            >
              Delete
            </SquaredRedButton>
            <SquaredPurpleButton
              className={`${styles.cancelButton}`}
              type="button"
              disabled={deleting}
              onClick={() => onClose()}
            >
              Cancel
            </SquaredPurpleButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LiveEventDeleteModal;
