import React from 'react';

import loadingIcon from '../../../assets/icons/loading.gif';
import styles from './loading-icon.module.css';

const LoadingIcon: React.FC<{
  className?: string;
}> = ({
  className = '',
}) => {
  return (
    <img
      className={`${styles.loading} ${className}`}
      src={loadingIcon}
      alt="Loading..."
    />
  );
};

export default LoadingIcon;
