import { useState, useEffect } from 'react';
import Services from '../services';

const useVerifiedToken = (accessToken: string | null) => {
  const [verified, setVerified] = useState<boolean | null>(null);

  useEffect(() => {
    if (accessToken && verified === null) {
      Services.Administrators.verifySession(accessToken).then((valid) => {
        setVerified(valid);
      });
    }
  }, [accessToken]);

  return verified;
};

export default useVerifiedToken;
