import React, { useState } from 'react';
import {
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom';
import styles from './reset-password.module.css';
import Services from '../../services';
import { Paths } from '../../routes';
import { PurpleButton } from '../../components';

const ResetPassword: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const history = useHistory();
  const location = useLocation<{
    email?: string;
    resetToken?: string;
  } | null | undefined>();
  const email = location.state?.email;
  const resetToken = location.state?.resetToken;

  const getFormErrors = () => {
    const errors = {
      email: !email,
      password: !password,
      confirmPassword: !confirmPassword,
      mismatchingPasswords: password !== confirmPassword,
    };

    return Object.values(errors).some((error) => !!error)
      ? errors
      : undefined;
  };

  return !email || !resetToken ? (
    <Redirect to={Paths.ForgotPassword} />
  ) : (
    <div className={`${styles.resetPassword}`}>
      <div className={`${styles.title} text-center`}>
        Reset Password
      </div>
      <form
        className={`${styles.form}`}
        onSubmit={(e) => {
          e.preventDefault();

          if (getFormErrors()) return;

          setSubmitting(true);
          Services.Administrators.resetPassword({
            email,
            resetToken,
            password,
          }).then((success) => {
            if (success) {
              history.push(Paths.Auth);
            }
            setSubmitting(false);
          });
        }}
      >
        <div className="form-group mb-4">
          <label className="font-weight-bolder">Email Address</label>
          <input
            type="email"
            className={`${styles.input} form-control`}
            value={email}
            disabled
            required
          />
        </div>
        <div className="form-group mb-4">
          <label className="font-weight-bolder">New Password</label>
          <input
            type="password"
            className={`${styles.input} form-control`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={submitting}
            required
          />
        </div>
        <div className="form-group mb-4">
          <label className="font-weight-bolder">Confirm Password</label>
          <input
            type="password"
            className={`${styles.input} form-control`}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={submitting}
            required
          />
        </div>
        <div className="form-group mb-0 d-flex justify-content-between align-items-center">
          <div className="mr-3 align-self-center text-danger">
            {getFormErrors()?.mismatchingPasswords
              && 'Passwords must match'}
          </div>
          <PurpleButton
            className={`${styles.submitButton}`}
            type="submit"
            disabled={submitting || !!getFormErrors()}
          >
            Reset Password
          </PurpleButton>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
