import React, { useEffect, useContext, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import {
  TopHeaderBar,
  Icons,
} from '../../components';
import styles from './live-events.module.css';
import { StoreContext } from '../../store/store';
import { actions } from '../../store';
import Services from '../../services';
import { getLocaleStartOfToday } from '../../utils/date-utils';
import { Paths } from '../../routes';
import Pages from '..';
import { useAccessToken } from '../../hooks';

const LiveEvents: React.FC = () => {
  const {
    dispatch,
  } = useContext(StoreContext);

  const [finishedLoading, setFinishedLoading] = useState(false);
  const accessToken = useAccessToken();

  useEffect(() => {
    Services.LiveEvents.getScheduledEvents({
      endsAfter: getLocaleStartOfToday().toISOString(),
    }, accessToken).then((scheduledEvents) => {
      dispatch(actions.fetchedScheduledEvents(scheduledEvents));
      setFinishedLoading(true);
    });

    return () => {
      dispatch(actions.exitLiveEvents());
    };
  }, []);

  return (
    <div>
      <TopHeaderBar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            <Switch>
              <Route path={Paths.CreateEvent}>
                <div className="row mb-3">
                  <div className="col">
                    <span className={styles.title}>Create Event</span>
                  </div>
                </div>
                {finishedLoading ? (
                  <Pages.CreateEvent />
                ) : (
                  <Icons.Loading className={`${styles.loading}`} />
                )}
              </Route>
              <Route path={Paths.EditEvent}>
                <div className="row mb-3">
                  <div className="col">
                    <span className={styles.title}>Edit Event</span>
                  </div>
                </div>
                {finishedLoading ? (
                  <Pages.EditEvent />
                ) : (
                  <Icons.Loading className={`${styles.loading}`} />
                )}
              </Route>
              <Route exact path={Paths.LiveEvents}>
                <Redirect to={Paths.CreateEvent} />
              </Route>
              <Route path={Paths.Default}>
                <Redirect to={Paths.NotFound} />
              </Route>
            </Switch>
          </div>
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default LiveEvents;
