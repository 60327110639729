import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import styles from './forgot-password.module.css';
import { ForgotPasswordForm, VerifyResetTokenForm } from '../../components';
import { Paths } from '../../routes';

const ForgotPassword: React.FC = () => {
  return (
    <div className={`${styles.forgotPassword}`}>
      <div className={`${styles.title} text-center`}>
        Forgot Password
      </div>
      <Switch>
        <Route path={Paths.VerifyResetToken}>
          <VerifyResetTokenForm />
        </Route>
        <Route exact path={Paths.ForgotPassword}>
          <ForgotPasswordForm />
        </Route>
        <Route path={Paths.Default}>
          <Redirect to={Paths.NotFound} />
        </Route>
      </Switch>
    </div>
  );
};

export default ForgotPassword;
